import type { PassThrough } from '@primevue/core';
import type { RadioButtonPassThroughOptions } from 'primevue/radiobutton';

export const radioButtonPassthrough: PassThrough<RadioButtonPassThroughOptions> =
  {
    root: {
      class: [
        'relative justify-center items-center shrink-0 mr-2',
        'inline-flex',
        'align-bottom',
        'w-[18px] h-[18px]',
        'cursor-pointer',
        'select-none',
      ],
    },
    input: ({ context }) => ({
      class: [
        'peer',
        'w-full ',
        'h-full',
        'absolute',
        'top-0 left-0',
        'rounded-full',
        'appearance-none',
        'cursor-pointer',
        context.checked
          ? 'blue-gradient border-none'
          : 'border dark:bg-dark-800 dark:border-dark-500 bg-light-50 border-light-400',
      ],
    }),
    icon: ({ context }) => ({
      class: [{ 'dark:bg-dark-800 bg-light-50': context.checked }],
    }),
    box: ({ context }) => ({
      class: [
        'flex justify-center items-center w-[10px] h-[10px] rounded-full',
        {
          'z-10 dark:bg-dark-800 bg-light-50': context.checked,
        },
      ],
    }),
  };
